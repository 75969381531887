import React from "react";

const ItemDetailInstruction = () => {
  return (
    <>
      {/* <div className="itemDetail-instruction ps-3"> */}
        <div className="itemDetail-instruction-title">Write Instruction</div>
        <div className="mt-2">
          <textarea
            maxLength="250"
            name="instruction"
            rows="1"
          ></textarea>
        </div>
      {/* </div> */}
    </>
  );
};

export default ItemDetailInstruction;
