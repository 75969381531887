import React from "react";
import "./App.css";
import Header from "./components/Header";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Cart from "./components/Cart";
import Checkout from "./components/Checkout";
import Menu from "./components/Menu";
import ItemDetail from "./components/ItemDetail";
import ReviewOrder from "./components/ReviewOrder";
import Footer from "./components/Footer";
import MyMenuCafe from "./components/MyMenuCafe";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <Header />
      <div className="p-0 m-auto">
          <Routes>
            <Route path="/:slug/:id" exact element={<Menu />} />

            <Route path="/" exact key="second-api" element={<Home />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            {/* <Route path="/menu" element={<Menu />} /> */}
            <Route path="/itemdetail" element={<ItemDetail />} />
            <Route path="/revieworder" element={<ReviewOrder />} />
          </Routes>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default App;
