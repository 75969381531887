import React, { useEffect, useState } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { RestaurantState } from "../../context/Context";
import { Api } from "../../service/Api";
import MenuItemFilter from "./MenuBannerMain/MenuItemFilter";
import MenuItemsContainer from "./MenuBannerMain/MenuItemsContainer";
import { Link } from "react-router-dom";

const MenuBannerMain = ({totalQty}) => {
  const [kitchen, setKitchen] = useState([]);
  const [menu, setMenu] = useState([]);
  const [activeKitchen, setActiveKitchen] = useState();
  const [search, setSearch] = useState();
  const { id, slug } = useParams();
  const { state, dispatch, setRestaurentId, setTableId } = RestaurantState();
  const history = useNavigate();
  useEffect(() => {
    Api.getKitchens(slug + "/" + id).then((res) => {
      if (res.data.meta.code === 301) {
        history("/" + res.data.meta.url);
      }
      if (res.data.meta.code === 200) {
        localStorage.setItem("slug", slug + "/" + id);
        setKitchen(res.data.data);
      }
    })
  }, [slug]);
  useEffect(() => {
    const data = {
      kitchenId: activeKitchen,
      search: search ? search : ""
    }
    Api.getMenu(slug + "/" + id,data).then((res) => {
      if (res.data.meta.code === 301) {
        history("/" + res.data.meta.url);
      }
      if (res.data.meta.code === 200) {
        setMenu(res.data.data[0].menu_items)
        setActiveKitchen(res.data.data[0].id)
        setRestaurentId({
          type: "ADD_RESTAURENT",
          payload: res.data.data[0].restaurent_id,
        });
        setTableId({
          type: "ADD_TABLE",
          payload: id,
        });
      }
    });
  }, [activeKitchen, slug])
  function handleSearch(value){
    setSearch(value);
    const data = {
      kitchenId: activeKitchen,
      search: value
    }
    Api.getMenu(slug + "/" + id, data).then((res) => {
      if (res.data.meta.code === 301) {
        history("/" + res.data.meta.url);
      }
      if (res.data.meta.code === 200) {
        setMenu(res.data.data[0].menu_items)
        setActiveKitchen(res.data.data[0].id)
      }
    });
  }
  return (
    <>
    <div className="menuBanner-header d-flex">
      <div className="search-bar d-flex items-center w-full">
        <input type={"text"} className="w-full m-2 rounded-full p-2" placeholder="Search Dish..." onChange={(e) => handleSearch(e.target.value)}/>
      </div>
      <div className="cart-icon">
        { totalQty > 0 ? <Link className="flex" to="/cart">
                <div><FaShoppingCart color="white" fontSize="25px" className="m-2" /></div>
                <div className="m-2">{totalQty}</div>
             </Link>
          : <><div><FaShoppingCart color="white" fontSize="25px" className="m-2" /></div>
          <div className="m-2">{totalQty}</div></>}
        </div>

    </div>
      <div className="menuBanner-main">
        <div className="d-flex overflow-x-auto px-2">
          {kitchen.map((item, i) => (
            <MenuItemFilter item={item} key={i} setActiveKitchen={setActiveKitchen} activeKitchen={activeKitchen} />
          ))}
        </div>
        <div className={state.length > 0 ? 'menuBanner-items-container-deactivate m-2 d-flex' :'menuBanner-items-container m-2 d-flex'}>
          <MenuItemsContainer menu={menu} />
        </div>
      </div>
    </>
  );
};

export default MenuBannerMain;
