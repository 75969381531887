import React, { useState } from "react";
import Modal from "react-modal";
import {RestaurantState} from "../context/Context";
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const [isTC, setIsTC] = useState(false);
  const [isContact, setIsContact] = useState(false);
  const [isPolicy, setIsPolicy] = useState(false);
  const { state, dispatch } = RestaurantState();
  const location = useLocation();
  const currentRoute = location.pathname;
  const slug = '/' + localStorage.getItem("slug");
  function tc() {
    setIsTC(!isTC);
  }
  function contactus() {
    setIsContact(!isContact);
  }
  function returnpolicy() {
    setIsPolicy(!isPolicy);
  }

  return (
    <>
      {state.length === 0 || currentRoute !== slug ?  <footer>
      <div className="flex items-center  justify-evenly  w-full h-[50px] bg-[#6f4e37]">
        <div className="text-white flex items-center">
          <div>privacy policy</div>
          <div className="mx-2">||</div>
          <div>About us</div>
        </div>
      </div>
      </footer> : ''}
    </>
  );
};

export default Footer;
