import React from "react";

const ServingTo = () => {
  return (
    <>
      <div className="reviewOrder-servingTo-cafe align-items-center">
        <div className="reviewOrder-servingTo-cafe-icon d-flex align-items-center justify-content-center">
          <i class="fa-solid fa-location-dot"></i>
        </div>
        <div className="reviewOrder-servingTo-cafe-text d-flex flex-column">
          <div>100</div>
          <div>My Menu Cafe</div>
        </div>
      </div>
    </>
  );
};

export default ServingTo;
