import React from "react";
import { Link } from "react-router-dom";

const HomeBannerMain = () => {
  return (
    <>
      <div className="homeBanner-main">
        <div className="d-flex justify-content-center align-items-center">
          <div className="divButton">
            <Link to="/menu">
            <div className="button">MENU</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBannerMain;
