import axios from "axios";

const getHeaders = () => {
  let headers = {
    Authorization: "",
  };
  if (localStorage.getItem("jwt")) {
    headers.Authorization = "Bearer " + localStorage.getItem("jwt");
    return headers;
  }
};
var baseUrl= "http://192.168.1.13/tecres/backend/public";

const callApi = (method, tag, data = {}) =>
  axios({
    method,
    url: tag,
    data,
    headers: getHeaders(),
  })
    .then((data) => data)
    .catch((error) => {
      return error.response;
    });

const Api = {
  getMenu: (slug, data) =>
  axios.get(`${baseUrl}/api/v1/menu/${slug}`, {params: {
    kitchenId: data?.kitchenId,
    search: data?.search
  }}),
  getKitchens: (slug,data) =>
    callApi(
      "GET",
      `${baseUrl}/api/v1/kitchenList/${slug}`,
    ),
  processCheckOut: (data) =>
    callApi(
      "POST",
      `${baseUrl}/api/v1/order`,
      data
    ),

  processOnLine: (data) => 
  callApi(
    "POST",
    `${baseUrl}/api/v1/pay`,
    data
  ),
};

export { Api };
