import React from "react";
import { Link } from "react-router-dom";
import "./itemDetail.css";
import ItemDetailCart from "./ItemDetail/ItemDetailCart";
import ItemDetailCategory from "./ItemDetail/ItemDetailCategory";
import ItemDetailExtras from "./ItemDetail/ItemDetailExtras";
import ItemDetailInstruction from "./ItemDetail/ItemDetailInstruction";
import ItemDetailOptions from "./ItemDetail/ItemDetailOptions";

const ItemDetail = () => {
  return (
    <>
        <div className="itemDetail d-flex flex-column">
          <div className="itemDetail-detail">
            <div>
              <div className="itemDetail-media">
                <div className="itemDetail-img"></div>
              </div>
              <div className="closeBtn d-flex justify-content-center align-items-center">
                <Link to="/menu">
                  <i className="fa-solid fa-xmark"></i>
                </Link>
              </div>
            </div>
            <div className="itemDetail-container d-flex justify-content-center align-items-center">
              <div className="itemDetails-desc-topTags d-flex">
                <div className="desc-topTag">
                  <div className="desc-topTag-text">Chef's Special</div>
                </div>
                <div className="desc-topTag">
                  <div className="desc-topTag-text">Best Seller</div>
                </div>
                <div className="desc-topTag">
                  <div className="desc-topTag-text">Our Favorite</div>
                </div>
              </div>
              <div className="itemDetail-favorite">
                <i className="fa-regular fa-heart"></i>
              </div>
            </div>
            <div className="itemDetail-description d-flex flex-column">
              <div className="itemDetail-description-name d-flex justify-content-center align-items-center">
                <div className="itemDetails-desc-badge">
                  <img
                    src="https://qr.mydigimenu.com/static/media/new.898bbe86.png"
                    alt=""
                  />
                </div>
                <div className="itemDetails-desc-badge">
                  <img
                    src="https://qr.mydigimenu.com/static/media/signature.b90e4269.png"
                    alt=""
                  />
                </div>
                <span>Bennykale Benedict</span>
              </div>
              <div className="itemDetail-description-time d-flex justify-content-center align-items-center m-1">
                <i className="fa-regular fa-clock"></i>
                <span>30</span>
              </div>
              <div className="itemDetail-description-category d-flex justify-content-center m-2">
                <ItemDetailCategory />
              </div>
              <div className="itemDetail-description-desc d-flex flex-column justify-content-center align-items-center m-2">
                <span>
                  Two poached eggs served with sautéed asparagus and kale
                  leaves.
                </span>
                <span>
                  Transfat 0g | Healthy Fats 15.34g | Carbs 36.42g | Protein 15g
                </span>
              </div>
            </div>
            <div className="itemDetail-options mt-5 ps-3">
              <div className="itemDetail-options-Title">OPTIONS</div>
              <ItemDetailOptions />
            </div>
            <div className="itemDetail-extras ps-3">
              <div className="itemDetail-extras-title">EXTRAS</div>
              <div className="itemDetail-extras-sides">
                <div className="itemDetail-extras-sides-title">SIDES</div>
                <ItemDetailExtras />
              </div>
            </div>
            <div className="itemDetail-instruction ps-3">
              <ItemDetailInstruction />
            </div>
          </div>
          <ItemDetailCart />
        </div>
    </>
  );
};

export default ItemDetail;
