import React from "react";
import "./styles.css";
import { RestaurantState } from "../context/Context";
import {Link} from "react-router-dom";
const Header = () => {
  const { state, dispatch } = RestaurantState();
  var totalQty = 0;
  for (let i = 0; i < state.length; i++) {
    totalQty = totalQty + state[i]?.quantity;
  }
  const backURL = localStorage.getItem("slug") ? '/' + localStorage.getItem("slug") : '/';
  return (
    <>
      <div className="text-center bg-[#6f4e37] sticky	top-0	">
        <Link to={backURL}>
          <img className="rounded h-[50px] w-[60px] pt-1 pb-1 m-auto" src="https://images.mydigimenu.com/media/hjtNsdOtSb/hjtNsdOtSb-05122111126050661.JPG"/>
        </Link>
      </div>
    </>
  );
};

export default Header;
