import React from "react";

const ItemDetailExtras = () => {
  return (
    <>
      <div className="itemDetail-extras-item d-flex flex-row">
        <div className="itemDetail-extras-item-left d-flex flex-row align-items-center">
          <div className="d-flex dlex-row justify-content-center align-items-center">
            <div className="itemDetail-extras-item-btn">
              <i class="fa-solid fa-minus"></i>
            </div>
            <div> 0 </div>
            <div className="itemDetail-extras-item-btn">
              <i class="fa-solid fa-plus"></i>
            </div>
          </div>
          <span>White Rice</span>
        </div>
        <div className="itemDetail-extras-item-right d-flex align-items-center justify-content-center ms-auto">
          <div>+ AED 13</div>
        </div>
      </div>
    </>
  );
};

export default ItemDetailExtras;
