import React, {useEffect} from "react";
import MenuItemDetail from "./MenuItemDetail";
import {RestaurantState} from "../../../context/Context";
import {useParams} from "react-router-dom";

const MenuItemsContainer = ({menu}) => {
  const { dispatch } = RestaurantState();
  const { id, slug } = useParams();
  useEffect(() => {
    if(localStorage.getItem('slug') !== slug + "/" + id){
      localStorage.setItem('cartState', JSON.stringify( []))
    }
    const storedArrayString = localStorage.getItem('cartState');
    dispatch({
      type: "SET_CART_ITEMS",
      payload: storedArrayString ? JSON.parse(storedArrayString) : [],
    });
  },[])
  return (
    <>
      <div className="itemContainer-menuName"> Menu </div>
      {menu.length > 0 ? menu.map((item, i) => (
        <MenuItemDetail item={item} />
      )): <div className="text-white text-center">No record found</div>}
    </>
  );
};

export default MenuItemsContainer;
