import React from "react";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { RestaurantState } from "../context/Context";
import Table from 'react-bootstrap/Table';
import { Api } from "../service/Api";
import {PaytmButton} from "./paytm-button/paytmButton";


function Checkout() {
  const { state, restaurentId, tableId } = RestaurantState();
  const orderDetails1 = state.map(v => ({...v, table_id: tableId, menu_id: v.id, status: 1, tax: 0, sub_total: parseInt(v.price) * parseInt(v.quantity), total: parseInt(v.price) * parseInt(v.quantity), item_name: v.name}));
  const orderDetails = orderDetails1.map(({id, name, price, ...rest}) => {
    return rest;
  });
  const [total, setTotal] = useState();
  useEffect(() => {
    setTotal(
      state.reduce((acc, curr) => acc + Number(curr.price) * curr.quantity, 0)
    );
  }, [state]);
  function processCheckoutWithouCard() {
    const data = {
      "restaurent_id": restaurentId,
      "payment_method":1,
      "sub_total":total,
      "tax":0,
      "total": total,
      "status":1,
      "order_details" : orderDetails,
    }
    Api.processCheckOut(data).then((res) => {
    })
  }
  return (
    <div id="checkout-main">
      <div id="checkout">
        <div className="checkout-div">Checkout (3) items</div>
        <div className="checkout-items">
      <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Dish Name</th>
          <th>Quantity</th>
          <th>Price (per Qty.)</th>
        </tr>
      </thead>
      <tbody>
          {state.map((q,i) => (
            <tr>
          <td>1</td>
          <td>{q.name}</td>
          <td>{q.quantity}</td>
          <td>{q.price}</td>
        </tr>
          ))}
      </tbody>
    </Table>
      </div>
      <div className="price">Subtotal: {total}</div>
      <Button type="button" disabled={state.length === 0} onClick={() => processCheckoutWithouCard()}>
        Checkout
      </Button>
        <PaytmButton />
      </div>

    </div>
  );
}

export default Checkout;
