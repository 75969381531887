import React, {useEffect} from "react";
import { RestaurantState } from "../../../context/Context";

const MenuItemDetail = ({ item }) => {
  const { state, dispatch } = RestaurantState();
  var foundInCart = false;
  state.map((q, i) => {
    if (q.item_name === item.item_name && q.quantity > 0) {
      foundInCart = true;
    }
  });
  var qty = 0;
  state.forEach((a, i) => {
    if (a.id === item.id) {
      qty = a.quantity;
    }
  });

  return (
    <>
      <div className="itemContainer-itemDetails d-flex">
        <div className="itemDetails-img">
          <img src={item?.image} alt="" />
        </div>
        <div className="itemDetails-desc d-flex">
          <div className="d-flex flex-column">
            <div className="itemDetails-desc-topTags d-flex">
              <div className="desc-topTag">
                <div className="desc-topTag-text">Chef's Special</div>
              </div>
              <div className="desc-topTag">
                <div className="desc-topTag-text">Best Seller</div>
              </div>
              <div className="desc-topTag">
                <div className="desc-topTag-text">Our Favorite</div>
              </div>
            </div>
              <div className="itemDetails-desc-itemName d-flex">
                <div className="itemDetails-desc-badge mt-[2px]">
                  <img
                    src="https://qr.mydigimenu.com/static/media/new.898bbe86.png"
                    alt=""
                  />
                </div>
                <div className="itemDetails-desc-badge mt-[2px]">
                  <img
                    src="https://qr.mydigimenu.com/static/media/signature.b90e4269.png"
                    alt=""
                  />
                </div>
                <span>{item.item_name}</span>
              </div>
            {item?.description ? <div className="itemDetails-desc-itemDesc">
              <span>
                {item?.description}
              </span>
            </div> : ''}
          </div>
          <div className="itemDetails-desc-itemPrice">₹{item.price}</div>
        </div>
        <div className="itemDetails-button d-flex flex-column mt-auto text-white">
            {!foundInCart && (
              <div onClick={() => {
                dispatch({
                  type: "INCREASE_QTY",
                  payload: item,
                });
              }} className="itemDetails-button-btn d-flex justify-content-center align-items-center">
                Add
              </div>
            )}
            {foundInCart && (
              <>
                <div className="itemDetails-button-btn d-flex justify-content-around align-items-center">
                  <div onClick={() => {
                                      dispatch({
                                        type: "DECREASE_QTY",
                                        payload: item,
                                      });
                                    }}>-</div>
                  <div> {qty}</div>
                  <div
                    onClick={() => {
                      dispatch({
                        type: "INCREASE_QTY",
                        payload: item,
                      });
                    }}
                  >
                    +
                  </div>
                </div>
              
              </>
            )}

        </div>
      </div>
    </>
  );
};

export default MenuItemDetail;
