import React from "react";

const HomeBannerFooter = () => {
  return (
    <>
      <div className="homeBanner-footer">
        <div className="footer-logo">
            <div className="logo"></div>
        </div>
      </div>
    </>
  );
};

export default HomeBannerFooter;
