import React from "react";
import HomeBannerHeader from "./HomeBanner/HomeBannerHeader";
import HomeBannerMain from "./HomeBanner/HomeBannerMain";
import HomeBannerFooter from "./HomeBanner/HomeBannerFooter";

const HomeBanner = () => {
  return (
    <>
          <div className="homeBannerImg">
            <div className="homeBanner">
              <HomeBannerHeader />
              <HomeBannerMain />
              <HomeBannerFooter />
            </div>
          </div>
        
    </>
  );
};

export default HomeBanner;
