import React from "react";
import "./cart.css";
import { useEffect, useState, useRef } from "react";
import { RestaurantState } from "../context/Context";
import { Api } from "../service/Api";
import {Link, useNavigate} from "react-router-dom";
import { toast } from 'react-toastify';
import { FaArrowLeft } from "react-icons/fa";

const Cart = () => {
  const { state, dispatch } = RestaurantState();
  const { restaurentId, tableId } = RestaurantState();
  const [total, setTotal] = useState();
  const [isProcessingCash, setIsProcessingCash] = useState(false);
  const [isProcessingCard, setIsProcessingCard] = useState(false);
  const history = useNavigate();
  const backURL = localStorage.getItem("slug") ? '/' + localStorage.getItem("slug") : '/';
  useEffect(() => {
    const storedArrayString = localStorage.getItem('cartState');
    dispatch({
      type: "SET_CART_ITEMS",
      payload: storedArrayString ? JSON.parse(storedArrayString) : [],
    });
  },[])
  const orderDetails1 = state.map((v) => ({
    ...v,
    table_id: tableId,
    menu_id: v.id,
    status: 0,
    tax: 0,
    sub_total: parseFloat(v.price) * parseInt(v.quantity),
    total: parseFloat(v.price) * parseInt(v.quantity),
    item_name: v.item_name,
  }));
  const orderDetails = orderDetails1.map(({ id, name, price, ...rest }) => {
    return rest;
  });
  useEffect(() => {
    setTotal(
      state.reduce((acc, curr) => acc + Number(curr.price) * curr.quantity, 0)
    );
  }, [state]);

  function createOrder(payment_method, response) {
    const data = {
      restaurent_id: restaurentId,
      payment_method: payment_method,
      sub_total: total,
      tax: 0,
      total: total,
      status: 0,
      order_details: orderDetails,
      razorpay_payment_id:
        response && response != "" ? response.razorpay_payment_id : "",
      razorpay_order_id:
        response && response != "" ? response.razorpay_order_id : "",
      razorpay_signature:
        response && response != "" ? response.razorpay_signature : "",
    };
    response ? setIsProcessingCard(true) : setIsProcessingCash(true);
    Api.processCheckOut(data)
      .then((res) => {
        if(res.data.meta.code === 200){
          dispatch({
            type: "EMPTY_CART",
          });
          history("/" + localStorage.getItem("slug"));
          toast("Order placed successfully!");
        }
        toast.error(res.data.message)
      })
      .finally(() => {
        response ? setIsProcessingCard(false) : setIsProcessingCash(false);
      });
  }

  function generateOrderId() {
    // Generate a random string of 5 characters
    const randomString = Math.random().toString(36).substr(2, 5);

    // Get the current timestamp in milliseconds
    const timestamp = Date.now();

    // Combine the random string and timestamp to create a unique order ID
    const orderId = `ORDER-₹{timestamp}-₹{randomString}`;

    return orderId;
  }

  const handlePayNowClick = async () => {
  setIsProcessingCard(true)
    Api.processOnLine({
      amount: total, // set as per your requirement
      orderId: generateOrderId(), // generate a unique order ID
    })
      .then((res) => {
        handleRazorpayPayment(res.data.orderId);
      })
      .finally(() => {
        setIsProcessingCard(false);
      });
  };

  const handleRazorpayPayment = async (order_id) => {
    const options = {
      key: "rzp_test_a1appXSlT6iXJ9",
      amount: total * 100,
      currency: "INR", // change as per your requirement
      name: "Tecoreng",
      description: "Payment for your order",
      order_id: order_id,
      handler: function (response) {
        createOrder(2, response);
        dispatch({
          type: "EMPTY_CART",
        });
        history("/" + localStorage.getItem("slug"));
        toast("Order placed succesfully!");
      },
      theme: {
        color: "#F37254", // change as per your requirement
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };

  return (
    <>
      <div className="mt-1">
        <div className="cart d-flex flex-column max-h-[calc(85vh_-_135px)] overflow-x-auto ">
          <div className="flex px-2 mt-1">
            <div className="flex-1"><div className="bg-[#6f4e37]  rounded h-[35px] w-[35px] pt-[1px] ">
              <Link to={backURL}><FaArrowLeft color="white" fontSize="17px" className="m-2" /></Link>
            </div></div>
            <div className="flex items-center justify-center pt-[5px] text-lg font-bold">CHECKOUT</div>
            <div className="flex-1"></div>
          </div>
          {total === 0 ? <>
            <div className="text-center mt-5 font-black text-2xl">Your cart is empty</div>

          </> : ''}
          {state.map((item) => (
            <div className="bg-[#ffffff] rounded mt-3 mx-2 border-solid border  border-slate-400	 " key={item.id}>
              <div className="d-flex flex-row justify-between items-center p-1 w-100">
                <div className="d-flex justify-center items-center">
                  <div className="w-[75px] h-[60px] shadow-md hover:shadow-lg focus:shadow-xl outline-none">
                    <img className="rounded-l-lg bg-no-repeat bg-center w-[90px]" src={item.image} alt="" />
                  </div>
                  <div className="cartItem-details d-flex flex-column justify-content-center ms-2">
                    <div className="font-bold text-sm">{item.item_name}</div>
                    <div className="font-semibold">
                      {" "}
                      ₹{item.price}    <span className="text-gray-400 text-[10px]">(Price for single qunatity)</span>
                    </div>
                    <div className="text-gray-400 text-[10px]">{item.description ? item.description : "No Description Found"}</div>
                  </div>
                </div>
                <div className="itemDetails-button-btn d-flex justify-content-around align-items-center">
                  <div
                    className="text-white"
                    onClick={() => {
                      dispatch({
                        type: "DECREASE_QTY",
                        payload: item,
                      });
                    }}
                  >
                    -
                  </div>
                  <div className="text-white"> {item.quantity}</div>
                  <div
                    className="text-white"
                    onClick={() => {
                      dispatch({
                        type: "INCREASE_QTY",
                        payload: item,
                      });
                    }}
                  >
                    +
                  </div>
                </div>
              </div>
            </div>
          ))}

          { total > 0 ? <div className="bg-[#ffffff] rounded p-1 text-[12px] font-['SecondaryFont'] border-solid border  border-slate-400	  mx-2 mt-2">
            <div className="flex justify-content-between">
              <div>Sub Total</div>
              <div>{total}</div>
            </div>
            <div className="flex justify-content-between">
              <div>Tax</div>
              <div> 00.00</div>
            </div>
            <div className="flex justify-content-between">
              <div>Total</div>
              <div>{total}</div>
            </div>
          </div> : ''}
          <div className="bg-[#ffffff] rounded p-2 mt-2 fixed bottom-[40px] w-100">
            { total > 0 ? (<><div className="">
              {isProcessingCash ? (
                <div role="status" className="d-flex justify-center mr-2 mb-2">
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <button
                  type="button"
                  className="text-white bg-[#6f4e37] w-100 from-green-400  font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                  onClick={() => createOrder(1, "")}
                >
                  Process With Cash
                </button>
              )}
              {isProcessingCard ? (
                <div role="status" className="d-flex justify-center mr-2 mb-2">
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (<button
                  type="button"
                  className="text-white bg-[#6f4e37] w-100 from-green-400  font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                  onClick={handlePayNowClick}
                >
                  Pay Online
                </button>
              )}
            </div> </>) : ''}
          </div>
        </div>

      </div>
    </>

  );
};

export default Cart;
