import React from "react";

const MenuItemFilter = ({item, setActiveKitchen, activeKitchen}) => {
  const selected = "border-2 border-blue-500";
  return (
    <>
      <div className="pr-2">
        <div className="w-[120px] max-h-[120px]" onClick={() => {setActiveKitchen(item.id)}}>
          <img className={`w-[120px] h-[80px] rounded-lg ${activeKitchen === item.id && selected}`} src={item.brand_image} alt="" />
          <div className="text-center py-2 text-[12px] font-semibold	">{item.name}</div>
        </div>
      </div>
    </>
  );
};

export default MenuItemFilter;
