import React from "react";
import { Link } from "react-router-dom";

import { RestaurantState } from "../context/Context";
import "./menu.css";
import MenuBannerMain from "./MenuBanner/MenuBannerMain";

const Menu = () => {
  const { state, dispatch } = RestaurantState();
  var totalQty = 0;
  var totalPrice = 0;
  for (let i = 0; i < state.length; i++) {
    totalQty = totalQty + state[i].quantity;
    totalPrice = parseInt(totalPrice) + parseInt(state[i].price * state[i].quantity)
  }
  return (
    <>
      <div className="menuBanner">
        <div className="menuBanner-img">
          <MenuBannerMain totalQty={totalQty} />
          {state.length > 0 && (
            <div
              className="fixed left-0 w-100 bottom-0 flex bg-[#6f4e37] justify-content-between text-[#ffffff] py-[12px]">
              <div className="cartItem-reviewItem-left d-flex flex-column">
                <div className="cartItem-reviewItem-left-qty">
                  {totalQty} item's in CART
                </div>
                <div className="cartItem-reviewItem-left-price">Total:- ₹{totalPrice}</div>
              </div>
              <div className="cartItem-reviewItem-right">
                <Link to="/cart">
                <span>View Cart</span>
                <i className="fa-solid fa-caret-right"></i>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Menu;
